// A component to show 6 buttons containing some sample questions
// to guide the user through the chatbot conversation.

import { CompoundButton, Divider, Subtitle2, Title3, makeStyles, shorthands, tokens } from '@fluentui/react-components';
import { BotSparkle24Regular, DocumentAdd24Regular, Search24Regular } from '@fluentui/react-icons';
import debug from 'debug';
import React from 'react';
import aidaLogoDarkMode from '../../assets/logos/aida-1-darkmode.png';
import aidaLogo from '../../assets/logos/aida-1.png';
import { Constants } from '../../Constants';
import { IAppConfigResult, useAppConfig } from '../../libs/hooks/useAppConfig';
import { GetResponseOptions, useChat } from '../../libs/hooks/useChat';
import { AlertType } from '../../libs/models/AlertType';
import { ChatMessageType } from '../../libs/models/ChatMessage';
import { IAskResult } from '../../libs/semantic-kernel/model/AskResult';
import { IAppConfigValue } from '../../libs/services/AppConfigService';
import { useAppDispatch, useAppSelector } from '../../redux/app/hooks';
import { RootState } from '../../redux/app/store';
import { addAlert } from '../../redux/features/app/appSlice';
import { FeatureKeys } from '../../redux/features/app/AppState';
import { ChatScope } from '../../redux/features/conversations/ChatState';
import { updateBotResponseStatus } from '../../redux/features/conversations/conversationsSlice';
import { customTokens } from '../../styles';

const log = debug(Constants.debug.root).extend('chat-guidance');

const useClasses = makeStyles({
    root: {
        ...shorthands.overflow('hidden'),
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        alignItems: 'center',
        height: '100%',
        ...shorthands.gap(tokens.spacingVerticalM),
    },
    appName: {
        display: 'flex',
        gap: '10px',
        justifyContent: 'center',
        alignItems: 'center',
    },
    logo: {
        width: '36px',
        height: '48px',
    },
    titles: {
        textAlign: 'center',
    },
    guidanceButtonContainer: {
        display: 'flex',
        flexDirection: 'row',
        flexWrap: 'wrap',
        justifyContent: 'center',
        alignItems: 'center',
        ...shorthands.gap(tokens.spacingVerticalM),
        maxWidth: '900px',
        width: '100%',
        justifySelf: 'center',
    },
    guidanceButton: {
        ...shorthands.margin('2px', 0),
        flexBasis: '49%',
        flexGrow: 1,
        flexShrink: 1,
        justifyContent: 'left',
        ...shorthands.borderRadius(tokens.borderRadiusMedium),
        boxShadow: tokens.shadow2,
        '&:hover': {
            boxShadow: tokens.shadow4,
            backgroundColor: tokens.colorNeutralBackground3Hover,
        },
        minHeight: '80px',
        '& .fui-CompoundButton__contentContainer': {
            width: '100%',
        },
    },
    divider: {
        paddingTop: tokens.spacingVerticalM,
    },
});

interface IChatGuidanceProps {
    onSubmit: (options: GetResponseOptions) => Promise<IAskResult | null>;
}

interface IChatGuidance {
    scope: ChatScope;
    header: string;
    text: string;
}

export const ChatGuidance: React.FC<IChatGuidanceProps> = ({ onSubmit }) => {
    const [guidances, setGuidances] = React.useState<IChatGuidance[]>([]);
    const classes = useClasses();

    const { conversations, selectedId } = useAppSelector((state: RootState) => state.conversations);
    const chat = useChat();
    const dispatch = useAppDispatch();

    const { activeUserInfo, isInputDisabled, features } = useAppSelector((state: RootState) => state.app);
    const emailAddress = activeUserInfo?.email ?? '';
    const user = `${emailAddress}`;

    const appConfig = useAppConfig();

    // TODO: This needs to happen only once and not for every chat
    const getSplashGuidanceContent = async () => {
        const response: IAppConfigResult<IAppConfigValue[]> = await appConfig.getConfigMultipleValues(
            'Splash Guidance',
            user,
        );
        if (response.Success) {
            const values = response.Data;
            const updatedGuidances: IChatGuidance[] = [];
            values?.forEach((value) => {
                const scope = ChatScope[value.Category as keyof typeof ChatScope];
                const header = value.CategoryTitle ?? '';
                const text = value.Value;
                updatedGuidances.push({ scope, header, text });
            });
            setGuidances(updatedGuidances);
        }
    };

    // TODO: Add other scopes
    const getIconBasedOnChatScope = (chatScope: ChatScope): JSX.Element => {
        switch (chatScope) {
            case ChatScope.None:
                return <BotSparkle24Regular color={customTokens.colorMeBackground} />;
            case ChatScope.DocumentUpload:
                return <DocumentAdd24Regular color={customTokens.colorMeBackground} />;
            case ChatScope.Web:
                return <Search24Regular color={customTokens.colorMeBackground} />;
            default:
                return <BotSparkle24Regular color={customTokens.colorMeBackground} />;
        }
    };

    React.useEffect(() => {
        getSplashGuidanceContent().catch((e) => {
            log('error getting splash guidance', e);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (
        value: string,
        messageType: ChatMessageType = ChatMessageType.Message,
        scope: ChatScope = ChatScope.None,
    ) => {
        if (value.trim() === '') {
            return; // only submit if value is not empty
        }

        // if scope is DocumentUpload the set the scope to None
        if (scope === ChatScope.DocumentUpload) {
            scope = ChatScope.None;
        }

        const chatState = conversations[selectedId];

        // if scope is different from the current scope, update the chat scope
        if (scope !== chatState.chatScope) {
            await chat.editChat(
                selectedId,
                chatState.title,
                chatState.memoryBalance,
                scope,
            );
        }

        dispatch(updateBotResponseStatus({ chatId: selectedId, status: 'captAIn is typing' }));
        onSubmit({ value, messageType, chatId: selectedId }).catch((error) => {
            const message = `Error submitting chat input: ${(error as Error).message}`;
            log(message);
            dispatch(
                addAlert({
                    type: AlertType.Error,
                    message,
                }),
            );
        });
    };

    return (
        <div className={classes.root}>
            <div className={classes.appName}>
                {
                    features[FeatureKeys.DarkMode].enabled ? (
                        <img src={aidaLogoDarkMode} alt="captAIn" className={classes.logo} />
                    ) : (
                        <img src={aidaLogo} alt="captAIn" className={classes.logo} />
                    )
                }
                <Title3 className={classes.titles}>captAIn</Title3>
            </div>
            {guidances.length > 0 && (
                <Subtitle2 className={classes.titles}>Ask me a question or select from one of the samples below</Subtitle2>
            )}
            <div className={classes.guidanceButtonContainer}>
                {guidances.map((guidance, index) => (
                    <CompoundButton
                        title={guidance.text}
                        key={index}
                        className={classes.guidanceButton}
                        icon={getIconBasedOnChatScope(guidance.scope)}
                        secondaryContent={guidance.text}
                        disabled={isInputDisabled}
                        onClick={
                            !isInputDisabled
                                ? () => {
                                      handleSubmit(guidance.text, ChatMessageType.Message, guidance.scope).catch(
                                          (error) => {
                                              const message = `Error submitting chat input: ${
                                                  (error as Error).message
                                              }`;
                                              log(message);
                                              dispatch(
                                                  addAlert({
                                                      type: AlertType.Error,
                                                      message,
                                                  }),
                                              );
                                          },
                                      );
                                  }
                                : () => {}
                        }
                    >
                        {guidance.header}
                    </CompoundButton>
                ))}
                <Divider className={classes.divider} />
            </div>
        </div>
    );
};
