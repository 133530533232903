import {
    BrandVariants,
    GriffelStyle,
    Theme,
    createDarkTheme,
    createLightTheme,
    makeStyles,
    themeToTokensObject,
    tokens,
} from '@fluentui/react-components';

export const semanticKernelBrandRamp: BrandVariants = {
    10: '#0D1A2E', // Darkest shade
    20: '#11203A',
    30: '#162647',
    40: '#1B2C54',
    50: '#203864', // Base color
    60: '#2A4674',
    70: '#355484',
    80: '#406494',
    90: '#4B74A5',
    100: '#5684B6',
    110: '#6094C6',
    120: '#77A7D1',
    130: '#8EBADD',
    140: '#A5CCE8',
    150: '#BDDDF3',
    160: '#D4EDFF', // Lightest tint
};

const candcBrandRamp: BrandVariants = {
    10: '#0D1A2E',
    20: '#11203A',
    30: '#162647',
    40: '#1B2C54',
    50: '#203864',
    60: '#2A4674',
    70: '#355484',
    80: '#406494',
    90: '#4B74A5',
    100: '#5684B6',
    110: '#6094C6',
    120: '#77A7D1',
    130: '#8EBADD',
    140: '#A5CCE8',
    150: '#BDDDF3',
    160: '#D4EDFF',
};

export const semanticKernelLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(semanticKernelBrandRamp),
    colorMeBackground: '#203864',
};

export const semanticKernelDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(semanticKernelBrandRamp),
    colorMeBackground: '#203864',
};

export const candcLightTheme: Theme & { colorMeBackground: string } = {
    ...createLightTheme(candcBrandRamp),
    colorMeBackground: '#406494',
};

export const candcDarkTheme: Theme & { colorMeBackground: string } = {
    ...createDarkTheme(candcBrandRamp),
    colorMeBackground: '#203864',
};

export const customTokens = themeToTokensObject(candcLightTheme);

export const Breakpoints = {
    xSmall: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 430px)': style };
    },
    small: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media (max-width: 744px)': style };
    },
    medium: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media screen and (max-width: 790px)': style };
    },
    large: (style: GriffelStyle): Record<string, GriffelStyle> => {
        return { '@media screen and (max-width: 960px)': style };
    },
};

export const ScrollBarStyles: GriffelStyle = {
    overflowY: 'scroll',
    '&:hover': {
        '&::-webkit-scrollbar-thumb': {
            backgroundColor: tokens.colorScrollbarOverlay,
            visibility: 'visible',
        },
        '&::-webkit-scrollbar-track': {
            backgroundColor: tokens.colorNeutralBackground1,
            WebkitBoxShadow: 'inset 0 0 5px rgba(0, 0, 0, 0.1)',
            visibility: 'visible',
        },
    },
};

export const SharedStyles: Record<string, GriffelStyle> = {
    scroll: {
        height: '100%',
        ...ScrollBarStyles,
    },
};

export const useDialogClasses = makeStyles({
    root: {
        height: '515px',
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '10px',
    },
    scopes: {
        display: 'flex',
        flexDirection: 'column',
        rowGap: '5px',
        paddingLeft: '20px',
    },
    error: {
        color: '#d13438',
    },
    section: {
        display: 'flex',
        flexDirection: 'column',
        width: '100%',
        rowGap: '10px',
    },
    footer: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        minWidth: '175px',
    },
});
